define("cb-ui/routes/app/project/client/pitch", ["exports", "cb-ui/mixins/scrollbar-reset", "cb-ui/utils/download-file"], function (_exports, _scrollbarReset, _downloadFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EVALUATION_TYPE = 'pitch';

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    model: function model() {
      var quote = this.modelFor('app.project');
      return Ember.RSVP.hash({
        criteriaEvaluationGroups: this.store.query('criteria-evaluation-group', {
          quote_id: quote.get('id'),
          type: EVALUATION_TYPE,
          include: 'evaluations'
        }),
        quote: quote,
        proposals: this.store.query('proposal', {
          quote_id: quote.get('id')
        }),
        currentUser: this.get('sessionContext.currentUser'),
        companies: quote.get('companies'),
        evaluators: this.store.query('user', {
          quote_id: quote.get('id'),
          evaluation_type: EVALUATION_TYPE
        }),
        userProposalEvaluationStatuses: this.store.query('user-proposal-evaluation-status', {
          quote_id: quote.get('id'),
          evaluation_type: EVALUATION_TYPE
        }),
        proposalsEvaluations: this.store.query('proposal-evaluation', {
          quote_id: quote.get('id'),
          evaluation_type: EVALUATION_TYPE
        }),
        quoteEvaluationResults: quote.getEvaluationResults({
          evaluation_type: EVALUATION_TYPE
        }).catch(function (e) {
          return [];
        }),
        userQuoteRoleList: this.store.query('user-quote-role', {
          quote_id: quote.get('id')
        }),
        alluserQuoteRoleList: this.store.query('user-quote-role', {
          quote_id: quote.get('id'),
          all_users: true
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var quote = model.quote;
      var pitchQuoteCriteriaGroups = new Ember.A();
      model.criteriaEvaluationGroups.forEach(function (ceg) {
        var groupType = ceg.get('type');

        if (groupType === 'pitch') {
          pitchQuoteCriteriaGroups.addObject(ceg);
        }
      });
      controller.set('quote', model.quote);
      controller.set('pitchTemplate', null);
      controller.set('userQuoteRole', model.userQuoteRoleList.get('firstObject'));
      controller.set('allUserQuoteRoles', model.alluserQuoteRoleList);
      controller.set('evaluators', model.evaluators.sortBy('firstName').filter(function (item) {
        return item.get('id') !== model.currentUser.get('id');
      }));
      controller.set('quoteEvaluationResults', model.quoteEvaluationResults.data);
      controller.set('showProjectEvaluation', false);
      controller.set('showExternalProjectEvaluation', false);
      controller.set('showProjectResults', true);
      controller.set('criteriaGroups', pitchQuoteCriteriaGroups.sortBy('order')); //contains also the technical proposals

      controller.set('criteriaEvaluationGroups', model.criteriaEvaluationGroups);
      controller.set('userProposalEvaluationStatuses', model.userProposalEvaluationStatuses);
      controller.set('evaluationIsActive', quote.get('pitchEvaluationIsActive'));
      controller.set('propertyNameEvaluationIsActive', 'pitchEvaluationIsActive');
      controller.set('proposalsEvaluations', model.proposalsEvaluations);
      controller.set('proposals', model.proposals);
      controller.set('currentUser', model.currentUser);
      model.currentUser.get('company').then(function (company) {
        controller.set('currentUserCompany', company);
      });
      controller.set('showUserEvaluationInvitationModal', false);
      controller.set('invitationType', EVALUATION_TYPE);
      controller.set('beautyContestItems', model.beautyItems);
      controller.set('activeEvaluation', null);
      controller.set('activeEvaluationCategory', null);
      controller.set('awardedProposal', null);
      controller.set('activeProposal', null);
      controller.set('showEvaluationModal', null);
      controller.set('showAwardProjectModal', null);
      controller.set('showFinancialDetailsModal', null);
      controller.set('notYetRatedClass', '');
      quote.get('owner').then(function (owner) {
        owner.get('company').then(function (company) {
          controller.set('quoteCompany', company);
        });
      });
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.controller.set('pitchTemplate', null);
        this.refresh();
      },
      newDocumentToken: function newDocumentToken(id) {
        this.store.findRecord('quote-document', id).then(function (res) {
          (0, _downloadFile.default)(res.data.file, res.get('fileName'));
        });
      }
    }
  });

  _exports.default = _default;
});