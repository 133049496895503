define("cb-ui/controllers/app/project/client/evaluation", ["exports", "cb-ui/mixins/beauty-contest-invitation"], function (_exports, _beautyContestInvitation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_beautyContestInvitation.default, {
    notify: Ember.inject.service('notify'),
    quote: null,
    currentUser: null,
    userQuoteRole: null,
    proposals: new Ember.A(),
    activeEvaluation: null,
    activeEvaluationCategory: null,
    awardedProposal: null,
    dismissedProposal: null,
    activeProposal: null,
    showEvaluationModal: null,
    showAwardProjectModal: null,
    showDismissProjectModal: null,
    showFinancialDetailsModal: null,
    showEvaluationsIncompleteModal: false,
    beautyContestItems: new Ember.A(),
    criteriaEvaluations: new Ember.A(),
    notYetRatedClass: '',
    consultantName: '',
    quoteEvaluationResults: new Ember.A(),
    technicalCriteriaGroups: new Ember.A(),
    pitchCriteriaGroups: new Ember.A(),
    isCurrentUserReadOnly: Ember.computed('userQuoteRole.role', function () {
      return this.get('userQuoteRole.role') === 'readonly';
    }),
    quoteTechnicalEvaluationResults: new Ember.A(),
    quotePitchEvaluationResults: new Ember.A(),
    showTechnicalEvaluationDetails: false,
    showPitchEvaluationDetails: false,
    userIsQuoteAdmin: Ember.computed.equal('userQuoteRole.role', 'admin'),
    timePickerStartOptions: Ember.computed(function () {
      var minutes = new Ember.A(['00', '15', '30', '45']);
      var timePickerOptions = new Ember.A();

      var _loop = function _loop(i) {
        var hour = i.toString();

        if (hour.length !== 2) {
          hour = '0' + hour;
        }

        minutes.forEach(function (item) {
          timePickerOptions.addObject(hour + ':' + item);
        });
      };

      for (var i = 0; i < 24; i++) {
        _loop(i);
      }

      return timePickerOptions;
    }),
    timePickerEndOptions: Ember.computed('beautyContestProposal.beautyContestInvitationStartTime', function () {
      var timePickerStartOptions = this.get('timePickerStartOptions').slice();
      timePickerStartOptions.addObject('24:00');
      var timePickerOptions = new Ember.A();

      if (this.get('beautyContestProposal') && this.get('beautyContestProposal.beautyContestInvitationStartTime')) {
        var startTime = this.get('beautyContestProposal.beautyContestInvitationStartTime');
        timePickerStartOptions.forEach(function (timeOption) {
          if (startTime < timeOption) {
            timePickerOptions.addObject(timeOption);
          }
        });
        return timePickerOptions;
      }

      return timePickerStartOptions;
    }),
    consultantNameObserver: Ember.observer('activeEvaluationCategory', 'activeEvaluation', 'activeEvaluation.proposal', function () {
      var _this = this;

      if (this.get('activeEvaluation')) {
        this.get('activeEvaluation.proposal').then(function (proposal) {
          proposal.get('consultantUser').then(function (consultantUser) {
            _this.set('consultantName', consultantUser.get('fullName'));
          });
        });
      }
    }),
    evaluationModalTitle: Ember.computed('activeEvaluationCategory', 'activeEvaluation', 'consultantName', function () {
      return this.get('activeEvaluationCategory.titleEn') + ' Evaluation for proposal from ' + this.get('consultantName');
    }),
    technicalCriteriaEvaluations: Ember.computed.filterBy('criteriaEvaluations', 'type', 'technical_proposal'),
    beautyContestCriteriaEvaluations: Ember.computed.filterBy('criteriaEvaluations', 'type', 'beauty_contest'),
    colspanEmptyColumns: Ember.computed('proposal.length', function () {
      return 5 - this.get('proposals.length');
    }),
    tableColumnWidth: Ember.computed('proposals.length', function () {
      var proposalsLength = this.get('proposals.length');

      if (proposalsLength) {
        return (75 / parseInt(proposalsLength)).toString() + '%';
      }

      return 0;
    }),
    columns: Ember.computed('intl.locale.[]', 'proposals.length', function () {
      var columns = [{
        minWidth: 256,
        maxWidth: 256,
        heading: '',
        valuePath: 'title',
        isFixed: 'left'
      }];
      this.get('proposals').forEach(function (p) {
        columns.push({
          width: 150,
          valuePath: p.get('id'),
          company: p.get('company'),
          heading: '',
          headerComponent: 'ember-table/company-info'
        });
      });
      return columns;
    }),
    rows: Ember.computed('intl.locale.[]', 'proposals.length', 'quoteTechnicalEvaluationResults.length', function () {
      var _this2 = this;

      var proposals = this.get('proposals');
      var technicalCriteriaGroups = this.get('technicalCriteriaGroups');
      var pitchCriteriaGroups = this.get('pitchCriteriaGroups');
      var technicalData = {
        isCollapsed: true,
        title: {
          section: 'technical',
          cellComponent: 'ember-table/evaluation-title-cell',
          details: this.get('quote.technicalWeight'),
          technicalCriteriaGroups: this.get('technicalCriteriaGroups')
        },
        children: []
      };
      var pitchData = {
        isCollapsed: true,
        title: {
          section: 'pitch',
          cellComponent: 'ember-table/evaluation-title-cell',
          details: this.get('quote.pitchWeight'),
          pitchCriteriaGroups: this.get('pitchCriteriaGroups')
        },
        children: []
      };
      var financialData = {
        title: {
          section: 'financial',
          cellComponent: 'ember-table/evaluation-title-cell',
          details: this.get('quote.financialWeight')
        }
      };
      var totalData = {
        title: 'Total'
      };
      var totalProjectData = {};
      var projectVolumeAtAwardingData = {};
      totalProjectData['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.totalProject'),
        description: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.totalProjectDetails'),
        cellComponent: 'ember-table/criteria-info'
      };
      projectVolumeAtAwardingData['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.totalProjectAtAwarding'),
        description: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.totalProjectAtAwardingDetails'),
        cellComponent: 'ember-table/criteria-info'
      };
      var awardData = {
        title: {
          section: 'award',
          cellComponent: 'ember-table/evaluation-title-cell',
          details: this.get('intl').t('app.projectClient.evaluation.label.finalEvaluation.details')
        }
      };
      var feedbackData = {
        title: {
          section: 'feedback',
          cellComponent: 'ember-table/evaluation-title-cell',
          details: this.get('intl').t('app.projectClient.evaluation.label.feedback.details')
        }
      };
      proposals.forEach(function (p) {
        var transitions = p.get('finalProposalTotalHistory') ? JSON.parse(p.get('finalProposalTotalHistory').replaceAll("'", "\"")) : {
          transitions: []
        };
        technicalData[p.get('id')] = {
          cellComponent: 'ember-table/evaluation-score-cell',
          section: 'technical',
          proposal: p,
          quoteEvaluationResults: _this2.get('quoteEvaluationResults')
        };
        pitchData[p.get('id')] = {
          cellComponent: 'ember-table/evaluation-score-cell',
          section: 'pitch',
          proposal: p,
          quoteEvaluationResults: _this2.get('quoteEvaluationResults')
        };
        financialData[p.get('id')] = {
          cellComponent: 'ember-table/evaluation-score-cell',
          section: 'financial',
          proposal: p,
          quoteEvaluationResults: _this2.get('quoteEvaluationResults')
        };
        totalData[p.get('id')] = {
          cellComponent: 'ember-table/evaluation-score-cell',
          section: 'total',
          proposal: p,
          quoteEvaluationResults: _this2.get('quoteEvaluationResults')
        };
        totalProjectData[p.get('id')] = {
          proposal: p,
          transitions: transitions,
          cellComponent: 'ember-table/financial-total-project-cell'
        };
        projectVolumeAtAwardingData[p.get('id')] = {
          proposal: p,
          timestamp: transitions.transitions.length > 0 ? transitions.transitions[transitions.transitions.length - 1].timestamp : null,
          changedBy: transitions.transitions.length > 0 ? transitions.transitions[transitions.transitions.length - 1].changed_by : null,
          cellComponent: 'ember-table/financial-total-proposal-cell'
        };
        awardData[p.get('id')] = {
          cellComponent: 'ember-table/evaluation-award-cell',
          proposal: p
        };
        feedbackData[p.get('id')] = {
          cellComponent: 'ember-table/evaluation-feedback-cell',
          proposal: p,
          userIsQuoteAdmin: p
        };
      });
      var technicalRows = [];
      technicalCriteriaGroups.forEach(function (group) {
        var data = {
          title: group.get('title'),
          rowComponent: 'ember-table/full-row-cell'
        };
        technicalRows.push(data);
        group.get('evaluations').forEach(function (criteria) {
          var rowData = {
            title: {
              cellComponent: 'ember-table/criteria-info',
              title: criteria.get('title'),
              description: criteria.get('description')
            }
          };
          proposals.forEach(function (p) {
            rowData[p.get('id')] = {
              cellComponent: 'ember-table/result-data',
              criteria: criteria,
              proposal: p,
              criteriaScore: true,
              quoteEvaluationResults: _this2.get('quoteTechnicalEvaluationResults')
            };
          });
          technicalRows.push(rowData);
        });
        technicalData['children'] = technicalRows;
      });
      var pitchRows = [];
      pitchCriteriaGroups.forEach(function (group) {
        var data = {
          title: group.get('title'),
          rowComponent: 'ember-table/full-row-cell'
        };
        pitchRows.push(data);
        group.get('evaluations').forEach(function (criteria) {
          var rowData = {
            title: {
              cellComponent: 'ember-table/criteria-info',
              title: criteria.get('title'),
              description: criteria.get('description')
            }
          };
          proposals.forEach(function (p) {
            rowData[p.get('id')] = {
              cellComponent: 'ember-table/result-data',
              criteria: criteria,
              proposal: p,
              criteriaScore: true,
              quoteEvaluationResults: _this2.get('quotePitchEvaluationResults')
            };
          });
          pitchRows.push(rowData);
        });
        pitchData['children'] = pitchRows;
      });
      var tableData = [technicalData, financialData];

      if (this.get('quote.pitchWeight') > 0) {
        tableData.push(pitchData);
      }

      tableData.push(totalData);
      tableData.push(totalProjectData);

      if (this.get('userIsQuoteAdmin')) {
        tableData.push(projectVolumeAtAwardingData);
      }

      tableData.push(awardData);
      tableData.push(feedbackData);
      return tableData;
    }),
    actions: {
      changeEvaluationScore: function changeEvaluationScore(proposalEvaluation, evaluationCategory) {
        this.set('showEvaluationModal', true);
        this.set('activeEvaluation', proposalEvaluation);
        this.set('activeEvaluationCategory', evaluationCategory);
      },
      closeEvaluationModal: function closeEvaluationModal() {
        this.set('showEvaluationModal', false);
        this.get('activeEvaluation').rollbackAttributes();
        this.set('activeEvaluation', null);
        this.set('activeEvaluationCategory', null);
      },
      openAwardProjectModal: function openAwardProjectModal(proposal) {
        var awardProject = true;
        var submittedProposals = this.get('proposals').filterBy('isSubmitted', true);
        var quoteEvaluationResults = this.get('quoteEvaluationResults');
        submittedProposals.forEach(function (proposal) {
          if (!proposal.get('financialQuotationData.evaluationFinalScore') || !quoteEvaluationResults.technical.proposals[proposal.get('id')] //TODO: Proposal pitch evaluations not mandatory
          // || !quoteEvaluationResults.pitch.proposals[proposal.get('id')]
          //
          ) {
              awardProject = false;
            }
        });

        if (awardProject) {
          if (proposal.get('status') === 'submitted') {
            this.set('awardedProposal', proposal);
            this.set('showAwardProjectModal', true);
          }
        } else {
          this.set('notYetRatedClass', 'label label-danger');
          this.set('awardedProposal', proposal);
          this.set('showEvaluationsIncompleteModal', true);
        }
      },
      awardProject: function awardProject() {
        var _this3 = this;

        var proposal = this.get('awardedProposal');
        var isValid = true;

        if (proposal.get('dontSendFeedback') == false && (proposal.get('feedback') == null || proposal.get('feedback') == '')) {
          isValid = false;
        }

        var sentProposals = this.get('proposals').filter(function (p) {
          return p.get('status') == 'submitted' || p.get('status') == 'accepted';
        });
        sentProposals.forEach(function (p) {
          if (p.get('dontSendFeedback') == false && (p.get('feedback') == null || p.get('feedback') == '')) {
            isValid = false;
          }
        });

        if (isValid == false) {
          this.get('notify').error(this.get('intl').t('notifyMessages.noEmptyFeedback').toString());
          return;
        }

        if (proposal.get('status') === 'submitted') {
          proposal.set('status', 'awarded');
          var validProposals = this.get('proposals').filter(function (p) {
            return p.get('status') !== 'declined';
          });
          validProposals.forEach(function (p) {
            if (p.get('id') === proposal.get('id')) {
              p.set('status', 'awarded');
            } else {
              if (p.get('status') === 'submitted') {
                p.set('status', 'lost');
              } else {
                if (p.get('status') !== 'dismissed') {
                  p.set('status', 'dismissed');
                }
              }
            }
          });
          var lostProposals = validProposals.filter(function (p) {
            return p.get('status') === 'lost' || p.get('status') === 'dismissed';
          });
          proposal.save().then(function () {
            _this3.get('notify').success(_this3.get('intl').t('notifyMessages.projectAwarded').toString());
          });
          lostProposals.forEach(function (p) {
            p.save();
          });
        }

        this.set('showAwardProjectModal', false);
        this.set('showEvaluationsIncompleteModal', false);
        this.set('awardedProposal', null);
      },
      closeAwardProjectModal: function closeAwardProjectModal() {
        this.set('showAwardProjectModal', false);
        this.set('awardedProposal', null);
      },
      openDismissProjectModal: function openDismissProjectModal(proposal) {
        if (proposal.get('status') === 'submitted') {
          this.set('dismissedProposal', proposal);
          this.set('showDismissProjectModal', true);
        } else {
          this.set('notYetRatedClass', 'label label-danger');
          this.set('dismissedProposal', proposal);
          this.set('showEvaluationsIncompleteModal', true);
        }
      },
      dismissProject: function dismissProject() {
        var _this4 = this;

        var proposal = this.get('dismissedProposal');
        proposal.set('keepEvaluationsAfterDismissal', true);
        var isValid = true;

        if (proposal.get('dontSendFeedback') == false && (proposal.get('feedback') == null || proposal.get('feedback') == '')) {
          isValid = false;
        }

        if (isValid == false) {
          this.get('notify').error(this.get('intl').t('notifyMessages.noEmptyFeedback').toString());
          return;
        }

        if (proposal.get('status') === 'submitted') {
          proposal.set('status', 'dismissed');
          proposal.save().then(function () {
            _this4.get('notify').success(_this4.get('intl').t('notifyMessages.projectDismissed').toString());
          });
        }

        this.set('showDismissProjectModal', false);
        this.set('showEvaluationsIncompleteModal', false);
        this.set('dismissedProposal', null);
      },
      dismissProjectAndDiscardEvaluation: function dismissProjectAndDiscardEvaluation() {
        var _this5 = this;

        var proposal = this.get('dismissedProposal');
        proposal.set('keepEvaluationsAfterDismissal', false);
        var isValid = true;

        if (proposal.get('dontSendFeedback') == false && (proposal.get('feedback') == null || proposal.get('feedback') == '')) {
          isValid = false;
        }

        if (isValid == false) {
          this.get('notify').error(this.get('intl').t('notifyMessages.noEmptyFeedback').toString());
          return;
        }

        if (proposal.get('status') === 'submitted') {
          proposal.set('status', 'dismissed');
          proposal.save().then(function () {
            _this5.get('notify').success(_this5.get('intl').t('notifyMessages.projectDismissed').toString());
          });
        }

        this.set('showDismissProjectModal', false);
        this.set('showEvaluationsIncompleteModal', false);
        this.set('dismissedProposal', null);
      },
      closeDismissProjectModal: function closeDismissProjectModal() {
        this.set('showDismissProjectModal', false);
        this.set('dismissedProposal', null);
      },
      closeWarningModal: function closeWarningModal() {
        this.set('showEvaluationsIncompleteModal', false);
        this.set('awardedProposal', null);
        this.set('dismissedProposal', null);
      },
      openFinancialDetailsModal: function openFinancialDetailsModal(proposal) {
        this.set('activeProposal', proposal);
        this.set('showFinancialDetailsModal', true);
      },
      closeFinancialDetailsModal: function closeFinancialDetailsModal() {
        this.set('showFinancialDetailsModal', false);
        this.set('activeProposal', null);
      },
      saveEvaluation: function saveEvaluation(proposalEvaluation) {
        var _this6 = this;

        proposalEvaluation.save().then(function (savedEvaluation) {
          savedEvaluation.reload();
          savedEvaluation.get('proposal').then(function (proposal) {
            proposal.reload();
          });

          _this6.set('showEvaluationModal', false);

          _this6.set('activeEvaluation', null);

          _this6.set('activeEvaluationCategory', null);
        });
      },
      presentationStartChanged: function presentationStartChanged(value) {
        var proposal = this.get('beautyContestProposal');

        if (proposal) {
          proposal.set('beautyContestInvitationStartTime', value);
          var beautyContestEndTime = proposal.get('beautyContestInvitationEndTime');

          if (beautyContestEndTime && beautyContestEndTime <= proposal.get('beautyContestInvitationStartTime')) {
            proposal.set('beautyContestInvitationEndTime', null);
          }
        }
      },
      presentationEndChanged: function presentationEndChanged(value) {
        var proposal = this.get('beautyContestProposal');

        if (proposal) {
          proposal.set('beautyContestInvitationEndTime', value);
          var beautyContestStartTime = proposal.get('beautyContestInvitationStartTime');

          if (beautyContestStartTime && beautyContestStartTime >= proposal.get('beautyContestInvitationEndTime')) {
            proposal.set('beautyContestInvitationStartTime', null);
          }
        }
      },
      toggleDisplayTable: function toggleDisplayTable(containerId) {
        var $container = Ember.$('#' + containerId);
        $container.prev('h4').find('span').toggleClass('glyphicon-triangle-bottom glyphicon-triangle-right');
        $container.fadeToggle();
      },
      goToEvaluation: function goToEvaluation(evaluationType) {
        var quote = this.get('quote');

        if (evaluationType === 'technical') {
          this.transitionToRoute('app.project.client.technical', quote);
        } else if (evaluationType === 'financial') {
          this.transitionToRoute('app.project.client.financial', quote);
        } else if (evaluationType === 'pitch') {
          this.transitionToRoute('app.project.client.pitch', quote);
        }
      },
      toggleEvaluationDetails: function toggleEvaluationDetails(evaluationType) {
        if (evaluationType === 'technical') {
          this.toggleProperty('showTechnicalEvaluationDetails');
        } else if (evaluationType === 'pitch') {
          this.toggleProperty('showPitchEvaluationDetails');
        }
      }
    }
  });

  _exports.default = _default;
});