define("cb-ui/controllers/app/rfp/custom-cover-letter", ["exports", "cb-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    localCoverLetterDocument: null,
    quote: null,
    blockPrevious: false,
    actions: {
      addCoverLetterDocument: function addCoverLetterDocument(file) {
        var _this = this;

        this.store.find('quote-cover-letter-document', file.id).then(function (currentFile) {
          _this.set('localCoverLetterDocument', currentFile);

          _this.get('notify').success(_this.get('intl').t('notifyMessages.fileUploaded').toString());

          _this.set('blockPrevious', true);

          var quote = _this.get('quote');

          quote.set('coverLetterDocument', _this.get('localCoverLetterDocument'));
          new Ember.RSVP.Promise(function (resolve, reject) {
            if (!quote.get('coverLetterDocument.file')) {
              _this.get('notify').error('You have to upload a cover letter document');

              reject();
            } else {
              quote.set('coverLetterTypeWasChosen', true);
              quote.set('uploadedCoverLetter', true);
              quote.save().then(function () {
                _this.set('blockPrevious', false);

                resolve();
              }).catch(function () {
                _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());

                reject();

                _this.set('blockPrevious', false);
              });
            }
          });
        });
      },
      removeCoverLetterDocument: function removeCoverLetterDocument() {
        this.set('localCoverLetterDocument', null);
      },
      previous: function previous() {
        if (!this.get('blockPrevious')) {
          this.transitionToRoute('app.rfp.criteria-evaluation');
        }
      },
      next: function next(callback) {
        var _this2 = this;

        this.set('blockPrevious', true);
        var quote = this.get('quote');
        quote.set('coverLetterDocument', this.get('localCoverLetterDocument'));
        var promise = new Ember.RSVP.Promise(function (resolve, reject) {
          if (!quote.get('coverLetterDocument.file')) {
            _this2.get('notify').error('You have to upload a cover letter document');

            reject();
          } else {
            quote.set('coverLetterTypeWasChosen', true);
            quote.set('uploadedCoverLetter', true);
            quote.save().then(function () {
              _this2.set('blockPrevious', false);

              window.location.href = "".concat(_environment.default.CANONICAL_DOMAIN_NEXT, "/project/").concat(_this2.get('quote').id, "/review-and-submit");
              resolve();
            }).catch(function () {
              _this2.get('notify').error(_this2.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());

              reject();

              _this2.set('blockPrevious', false);
            });
          }
        });
        callback(promise);
      }
    }
  });

  _exports.default = _default;
});