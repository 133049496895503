define("cb-ui/controllers/app/rfp/confirm", ["exports", "ember-ajax", "cb-ui/config/environment"], function (_exports, _emberAjax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    sessionContext: Ember.inject.service(),
    quote: null,
    userQuoteRole: null,
    quoteCompanies: new Ember.A(),
    criteriaEvaluations: new Ember.A(),
    beautyContestCriteriaEvaluations: Ember.computed.filterBy('criteriaEvaluations', 'type', 'beauty_contest'),
    showRfpConfirmationModal: null,
    isQuoteOwner: false,
    showEditCoverLetterModal: false,
    showCreateNewCoverLetterModal: false,
    showQuoteCompanyContactsModal: false,
    showConfirmModal: false,
    localQuoteCompanyContacts: new Ember.A(),
    pitchQuoteCriteriaGroups: new Ember.A(),
    technicalQuoteCriteriaGroups: new Ember.A(),
    currentUser: null,
    userStatus: null,
    emailSent: null,
    userIsQuoteAdmin: Ember.computed.equal('userQuoteRole.role', 'admin'),
    extraConsultants: Ember.computed('quoteCompanies', function () {
      var extraCons = [];
      this.get('quoteCompanies').forEach(function (company) {
        if (company.get('extraConsultant') || company.get('consultantType.consultantTypeEn') === undefined) {
          extraCons.push(company);
        }
      });
      return extraCons;
    }),
    createQuoteCompanyContactsList: function createQuoteCompanyContactsList(quoteCompaniesContacts) {
      var _this = this;

      var companiesWithContacts = {};
      var localQuoteCompanyContacts = new Ember.A();
      var quote = this.get('quote');
      quoteCompaniesContacts.forEach(function (companyContact) {
        localQuoteCompanyContacts.addObject(Ember.Object.create({
          hasData: true,
          showAdd: false,
          companyContact: companyContact
        }));
        companiesWithContacts[companyContact.get('company.id')] = true;
      });
      this.get('quoteCompanies').forEach(function (company) {
        if (!(company.get('id') in companiesWithContacts)) {
          localQuoteCompanyContacts.addObject(Ember.Object.create({
            hasData: false,
            showAdd: true,
            companyContact: _this.store.createRecord('quote-company-contact', {
              quote: quote,
              company: company,
              name: '',
              email: '',
              phone: ''
            })
          }));
        }
      });
      this.set('localQuoteCompanyContacts', localQuoteCompanyContacts);
    },
    actions: {
      navbarTransitionToComponent: function navbarTransitionToComponent(componentId) {
        Ember.run.next(function () {
          Ember.$('html, body').animate({
            scrollTop: Ember.$('#' + componentId).offset().top - 200
          });
        });
      },
      previous: function previous() {
        this.transitionToRoute('app.rfp.cover-letter-choices');
      },
      confirm: function confirm() {
        var _this2 = this;

        this.set('showConfirmModal', false);
        var quote = this.get('quote');

        if (quote.get('rfpReleaseDays') < 0) {
          this.set('showErrorModal', true);
        } else {
          quote.save().then(function () {
            var self = _this2;

            _this2.store.createRecord('user-profile-status', {
              user: self.get('currentUser.id')
            }).save().then(function (userStatus) {
              _this2.set('userStatus', userStatus);

              if (userStatus.get('emailConfirmed') && userStatus.get('profileCompleted')) {
                quote.quoteProposals().then(function () {
                  quote.reload().then(function () {
                    _this2.set('showRfpConfirmationModal', true);
                  });
                }).catch(function (response) {
                  if (response.errors) {
                    var errorMessages = response.errors.map(function (item) {
                      return item.detail;
                    });

                    if (errorMessages.includes(_this2.get('intl').t('rfpReleaseAtLeastToday').toString())) {
                      _this2.get('notify').error(_this2.get('intl').t('rfpReleaseAtLeastToday').toString());
                    } else if (errorMessages.includes(_this2.get('intl').t('customCoverLetterError').toString())) {
                      _this2.get('notify').error(_this2.get('intl').t('customCoverLetterError').toString());
                    } else if (errorMessages.includes(_this2.get('intl').t('emailNotConfirmed').toString())) {
                      _this2.get('notify').error(_this2.get('intl').t('emailNotConfirmed').toString());
                    } else if (errorMessages.includes(_this2.get('intl').t('incompleteProfile').toString())) {
                      _this2.get('notify').error(_this2.get('intl').t('incompleteProfile').toString());
                    } else if (errorMessages.includes(_this2.get('intl').t('notValidatedByAgent').toString())) {
                      _this2.get('notify').error(_this2.get('intl').t('notValidatedByAgent').toString());
                    } else {
                      _this2.get('notify').error(_this2.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
                    }
                  } else {
                    _this2.get('notify').error(_this2.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
                  }
                });
              } else {
                _this2.set('showUserStatusModal', true);
              }
            }).catch(function () {
              _this2.set('userStatus', null);

              _this2.get('notify').error(_this2.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
            });
          }).catch(function () {
            _this2.get('notify').error(_this2.get('intl').t('notifyMessages.checkMandatoryFields').toString());
          });
        }
      },
      closeRfpConfirmationModal: function closeRfpConfirmationModal() {
        this.set('showRfpConfirmationModal', false);
        this.transitionToRoute('app.dashboard');
      },
      closeErrorModal: function closeErrorModal() {
        this.set('showErrorModal', false);
      },
      editCoverLetter: function editCoverLetter() {
        this.set('showEditCoverLetterModal', true);
      },
      closeEditCoverLetterModal: function closeEditCoverLetterModal() {
        this.set('showEditCoverLetterModal', false);
      },
      editExistingCoverLetter: function editExistingCoverLetter() {
        var quote = this.get('quote');

        if (quote.get('uploadedCoverLetter')) {
          this.transitionToRoute('app.rfp.custom-cover-letter');
        } else {
          this.transitionToRoute('app.rfp.visual-editor');
        }

        this.set('showEditCoverLetterModal', false);
      },
      createNewCoverLetter: function createNewCoverLetter() {
        this.set('showCreateNewCoverLetterModal', true);
        this.set('showEditCoverLetterModal', false);
      },
      closeCreateNewCoverLetterModal: function closeCreateNewCoverLetterModal() {
        this.set('showCreateNewCoverLetterModal', false);
      },
      newCustomCoverLetter: function newCustomCoverLetter() {
        var _this3 = this;

        var quote = this.get('quote');
        quote.set('coverLetterDocument', null);
        quote.set('coverLetterTypeWasChosen', false);
        quote.set('saveAsDraft', true);
        quote.save().then(function () {
          quote.set('saveAsDraft', false);

          _this3.transitionToRoute('app.rfp.custom-cover-letter');

          _this3.set('showCreateNewCoverLetterModal', false);
        });
      },
      newDefaultCoverLetter: function newDefaultCoverLetter() {
        var _this4 = this;

        var quote = this.get('quote');
        quote.set('coverLetterDocument', null);
        quote.set('coverLetterTypeWasChosen', false);
        quote.set('sections', new Ember.A());
        quote.set('saveAsDraft', true);
        quote.save().then(function () {
          quote.set('saveAsDraft', false);

          _this4.transitionToRoute('app.rfp.visual-editor');

          _this4.set('showCreateNewCoverLetterModal', false);
        });
      },
      showQuoteCompanyContacts: function showQuoteCompanyContacts() {
        this.set('showQuoteCompanyContactsModal', true);
      },
      closeQuoteCompanyContacts: function closeQuoteCompanyContacts() {
        this.set('showQuoteCompanyContactsModal', false);
        var quoteCompanyContacts = this.get('localQuoteCompanyContacts');
        quoteCompanyContacts.forEach(function (info) {
          var contact = info.get('companyContact');

          if (contact.get('id')) {
            contact.rollbackAttributes();
            info.setProperties({
              hasData: true,
              showAdd: false
            });
          } else {
            contact.setProperties({
              firstName: null,
              lastName: null,
              salutation: null,
              email: null,
              phone: null
            });
            info.setProperties({
              hasData: false,
              showAdd: true
            });
          }
        });
      },
      saveQuoteCompanyContacts: function saveQuoteCompanyContacts() {
        var _this5 = this;

        var quoteCompanyContacts = this.get('localQuoteCompanyContacts');
        var savePromises = [];
        quoteCompanyContacts.forEach(function (contactInfo) {
          var contact = contactInfo.get('companyContact');

          if (contactInfo.get('hasData')) {
            savePromises.push(contact.save());
          } else {
            if (contact.get('id')) {
              savePromises.push(contact.destroyRecord());
            } else {
              contact.setProperties({
                salutation: null,
                firstName: null,
                lastName: null,
                email: null,
                phone: null
              });
            }
          }
        });
        new Ember.RSVP.all(savePromises).then(function () {
          _this5.get('notify').success(_this5.get('intl').t('notifyMessages.quoteCompanyContactsUpdated').toString());

          _this5.set('showQuoteCompanyContactsModal', false);

          _this5.store.query('quote-company-contact', {
            quote: _this5.get('quote.id')
          }).then(function (quoteCompaniesContactsNew) {
            _this5.createQuoteCompanyContactsList(quoteCompaniesContactsNew);
          });
        }).catch(function () {
          _this5.get('notify').error(_this5.get('intl').t('notifyMessages.errorOccurredVerifyFields').toString());
        });
      },
      closeUserStatusModal: function closeUserStatusModal() {
        this.set('showUserStatusModal', false);
      },
      addContactInfo: function addContactInfo(companyId) {
        var contactsInfo = this.get('localQuoteCompanyContacts');
        var contact = contactsInfo.findBy('companyContact.company.id', companyId);
        contact.set('hasData', true);
        contact.set('showAdd', false);
      },
      removeContactInfo: function removeContactInfo(companyId) {
        var contactsInfo = this.get('localQuoteCompanyContacts');
        var contact = contactsInfo.findBy('companyContact.company.id', companyId);
        contact.set('hasData', false);
        contact.set('showAdd', true);
      },
      resendValidationEmail: function resendValidationEmail() {
        var _this6 = this;

        var url = _environment.default.backendAPI + '/api/send-validation-email/';
        (0, _emberAjax.default)(url, {
          type: 'POST',
          headers: {
            'Authorization': 'Bearer ' + this.get('session.data.authenticated.token')
          }
        }).then(function () {
          _this6.set('emailSent', true);
        });
      }
    }
  });

  _exports.default = _default;
});