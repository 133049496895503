define("cb-ui/controllers/app/project/client/proposals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    quote: null,
    proposals: null,
    companies: null,
    selectedCompany: null,
    selectedProposal: null,
    selectedProposals: new Ember.A(),
    currentUser: null,
    userIsQuoteAdmin: Ember.computed.equal('userQuoteRole.role', 'admin'),
    isCurrentUserReadOnly: Ember.computed('userQuoteRole.role', function () {
      return this.get('userQuoteRole.role') === 'readonly';
    }),
    actions: {
      changeSelectedProposal: function changeSelectedProposal(proposal) {
        var _this = this;

        if (proposal === this.get('selectedProposal')) {
          this.set('selectedProposal', null);
          this.set('selectedCompany', null);
          this.set('selectedProposals', new Ember.A());
        } else {
          this.set('selectedProposal', proposal);
          this.set('selectedCompany', proposal.get('company.content'));
          var qs = new Ember.A();
          this.get('proposals').forEach(function (proposal) {
            if (proposal.get('company.content') === _this.get('selectedCompany')) {
              qs.insertAt(0, proposal);
            }
          });
          this.set('selectedProposals', qs);
        }
      },
      changeSelectedCompany: function changeSelectedCompany(company) {
        var _this2 = this;

        this.set('selectedProposals', new Ember.A());

        if (company === this.get('selectedCompany')) {
          this.set('selectedCompany', null);
          this.set('selectedProposal', null);
        } else {
          this.set('selectedCompany', company);
          var qs = new Ember.A();
          var atLeastOneActive = false;
          this.get('proposals').forEach(function (proposal) {
            if (proposal.get('company.content') === company) {
              if (proposal.get('isActive')) {
                atLeastOneActive = true;

                _this2.set('selectedProposal', proposal);
              }

              qs.insertAt(0, proposal);
            }
          });

          if (!atLeastOneActive) {
            this.set('selectedProposal', null);
          }

          this.set('selectedProposals', qs);
        }
      }
    }
  });

  _exports.default = _default;
});