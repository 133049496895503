define("cb-ui/controllers/app/project/client/technical", ["exports", "cb-ui/mixins/evaluation"], function (_exports, _evaluation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_evaluation.default, {
    showWarningModal: false,
    userIsQuoteAdmin: Ember.computed.equal('userQuoteRole.role', 'admin'),
    isCurrentUserReadOnly: Ember.computed('userQuoteRole.role', function () {
      return this.get('userQuoteRole.role') === 'readonly';
    }),
    actions: {
      closeWarningModal: function closeWarningModal() {
        this.set('showWarningModal', false);
      },
      triggerWarningModal: function triggerWarningModal() {
        this.set('showWarningModal', true);
      }
    }
  });

  _exports.default = _default;
});